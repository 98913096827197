
import { Pagation } from "@/composables/usePagation";
import useRenderTable from "@/composables/useRenderTable";
import useRequest from "@/composables/useRequest";
import { RequestParams } from "@/types/request";
import useFetchSearch from "./composables/useFetchSearch";
import { defineComponent, reactive, toRefs, ref, onActivated } from "vue";
import { RUN_ENV, LANGUAGE } from "@/utils";
import { useRouter } from "vue-router";
import useCreateAndUpdate from "./composables/useCreateOrUpdate";
import useMessageTip from "@/composables/useMessageTip";
import copyField from "@/utils/copyField";
import { setSession } from "@/utils/sesssion";

export default defineComponent({
  name: "applicationVariable",
  setup() {
    const router = useRouter();
    const { tipMessage } = useMessageTip();
    const searchParams = reactive({
      name: ""
    });
    const tableData = reactive({
      list: [],
      total: 0
    });
    const currentPage = ref(1);
    const pageTableRef = ref<any>();
    const stopAutoGetData = ref<boolean>(false);
    // 分页以及获取数据
    const getData = async (pagationParams?: Pagation, flag?: boolean, params?: any) => {
      stopAutoGetData.value = flag == undefined ? false : true;
      currentPage.value = (pagationParams && pagationParams.pageIndex) || 1;
      const { response } = useRequest(useFetchSearch, params || searchParams);
      const { pagation } = useRenderTable(response);
      let {
        data: { list, count }
      } = await pagation(pagationParams);
      tableData.total = count;
      tableData.list = list;
    };

    onActivated(() => {
      handleConditionSearch();
    });
    // const handleConditionSearch = async () => {
    //   console.log(searchParams);
    //   await getData(pageTableRef.value.getCurrentPage(), true, searchParams);
    //   stopAutoGetData.value = false;
    // };

    const handleConditionSearch = async () => {
      await getData(pageTableRef.value.getCurrentPage(), true, searchParams);
      stopAutoGetData.value = false;
    };
    getData();
    const handleName = (val: string) => {
      if (!val) handleConditionSearch();
    };
    /**
     * 创建和更新
     */
    const dialogParams = reactive<RequestParams.CreateAndUpdateAppList>({
      id: 0,
      name: "",
      git_url: "",
      remark: "",
      run_env: null,
      code_language: ""
    });
    const { rules, dialogVisible, handleCreateApp, formRefs, handleUpdateApp, handleCloseDialog, handleDelete } = useCreateAndUpdate(
      getData,
      pageTableRef,
      dialogParams
    );
    const handleDetail = (row: any) => {
      setSession("appDetail", row);
      if (row.env_list) {
        setSession("currentEnvironment", row.env_list[0]);
      }
      router.push({
        name: "appDetail",
        query: Object.assign({ ...router.currentRoute.value.query })
      });
    };

    const environment = (val: any) => {
      let text = "";
      if (val && val.length) {
        val.forEach((element: any) => {
          text += element.name.replace("环境", "") + "/";
        });
      }
      if (text) text = text.substr(0, text.length - 1);
      return text;
    };

    // 复制源码地址
    const handleCopyUrl = (val: string) => {
      copyField(val);
    };

    return {
      handleCloseDialog,
      tipMessage,
      rules,
      handleConditionSearch,
      stopAutoGetData,
      getData,
      ...toRefs(tableData),
      pageTableRef,
      searchParams,
      dialogVisible,
      dialogParams,
      handleCreateApp,
      RUN_ENV,
      LANGUAGE,
      formRefs,
      environment,
      handleUpdateApp,
      handleCopyUrl,
      handleDelete,
      handleDetail,
      handleName,
      currentPage
    };
  }
});
